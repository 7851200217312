export function useHeaderAndNavbarHeight() {
  // Define and initialize the ref variables
  const navbarHeight = useState<number>('navbar-height', () => 0)
  const topHeaderHeight = useState<number>('top-header-height', () => 0)

  // Define computed properties
  const totalHeaderHeight = computed(
    () => topHeaderHeight.value + navbarHeight.value
  )

  const decorateValue = (v: MaybeRef) => `${unref(v)}px`

  // Return the computed properties
  return {
    navbarHeight,
    topHeaderHeight,
    totalHeaderHeight,
    decorateValue,
  }
}
